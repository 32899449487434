import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';


@Injectable({
  providedIn: 'root'
})
export class SessionServiceService {

  private sessionIdKey = 'sessionId';
  private sessionTtlKey = 'sessionTTL';
  private ttl = 60 * 60 * 1000 * 24; // 1 hour in milliseconds

  constructor() {
    this.initializeSession();
  }



  private initializeSession(): void {
    const storedTtl = localStorage.getItem(this.sessionTtlKey);
    const now = new Date().getTime();

    // Check if the stored session is expired
    if (!storedTtl || now > +storedTtl) {
      // Generate a new session ID and store it
      const sessionId = uuidv4();
      localStorage.setItem(this.sessionIdKey, sessionId);
      
      // Set the session TTL
      const expiryTime = now + this.ttl;
      localStorage.setItem(this.sessionTtlKey, expiryTime.toString());
    }
  }

  getSessionId(): string| null {
    return localStorage.getItem(this.sessionIdKey);
  }
}
