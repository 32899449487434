import { AuthchildGuard } from './core/@auth/authchild.guard';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Import Angular Modules
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

// Import Angular Material Modules
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { TcstatusUpdatePopupComponent } from './modules/dashboard/homedash/popups/tcstatus-update-popup/tcstatus-update-popup.component';
import { MilestonOverviewComponent } from './modules/dashboard/homedash/myBoard/mileston-overview/mileston-overview.component';
import { NgChartsModule } from 'ng2-charts';
import { CheckoutComponent } from './modules/shared/payment/checkout/checkout.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TopMenubarComponent } from './modules/shared/menubar/top-menubar/top-menubar.component';
import { SubscriptionBasicComponent } from './modules/shared/payment/checkout/subscription-basic/subscription-basic.component';
import { SubscriptionPremiumComponent } from './modules/shared/payment/checkout/subscription-premium/subscription-premium.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SubscriptionBannerComponent } from './modules/shared/banners/subscription-banner/subscription-banner.component';
import { CommonFooterComponent } from './modules/shared/footer/common-footer/common-footer.component';
import { ReviewCartComponent } from './modules/shared/payment/checkout/review-cart/review-cart.component';

//External library 
import { NgxPayPalModule } from 'ngx-paypal';

import { HomeComponent } from './modules/shared/home/home.component';
import { HomeTopeBannerComponent } from './modules/shared/home/home-tope-banner/home-tope-banner.component';
import { HomeMiddlebarComponent } from './modules/shared/home/home-middlebar/home-middlebar.component';
import { HomeBenefitsComponent } from './modules/shared/home/home-benefits/home-benefits.component';
import { HomeBottomBannerComponent } from './modules/shared/home/home-bottom-banner/home-bottom-banner.component';
import { HomeStepperComponent } from './modules/shared/home/home-stepper/home-stepper.component';
import { AboutComponent } from './modules/shared/about/about.component';
import { AbouttopBannerComponent } from './modules/shared/about/abouttop-banner/abouttop-banner.component';
import { AboutteamMembersComponent } from './modules/shared/about/aboutteam-members/aboutteam-members.component';
import { GetIntouchComponent } from './modules/shared/get-intouch/get-intouch.component';
import { GetInTouchBannerComponent } from './modules/shared/get-intouch/get-in-touch-banner/get-in-touch-banner.component';
import { ContactusFormComponent } from './modules/shared/get-intouch/contactus-form/contactus-form.component';
import { RefundPolicyComponent } from './modules/shared/other/refund-policy/refund-policy.component';
import { TermsConditionsComponent } from './modules/shared/other/terms-conditions/terms-conditions.component';
import { HowCredmateWorksComponent } from './modules/shared/how-credmate-works/how-credmate-works.component';
import { HowTopBannerComponent } from './modules/shared/how-credmate-works/how-top-banner/how-top-banner.component';
import { HowBodyBannerComponent } from './modules/shared/how-credmate-works/how-body-banner/how-body-banner.component';
import { HowstepperBannerComponent } from './modules/shared/how-credmate-works/howstepper-banner/howstepper-banner.component';
import { PaymentModalComponent } from './modules/shared/payment/checkout/payment-modal/payment-modal.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TitlePaymentModalComponent } from './modules/shared/payment/checkout/payment-modal/title-payment-modal/title-payment-modal.component';




const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' }, // Redirect to 'login' route by default
  // other routes...
];

@NgModule({
  declarations: [
    AppComponent,
    CheckoutComponent,
    TopMenubarComponent,
    SubscriptionBasicComponent,
    SubscriptionPremiumComponent,
    SubscriptionBannerComponent,
    CommonFooterComponent,
    ReviewCartComponent,
    HomeComponent,
    HomeTopeBannerComponent,
    HomeMiddlebarComponent,
    HomeBenefitsComponent,
    HomeBottomBannerComponent,
    HomeStepperComponent,
    AboutComponent,
    AbouttopBannerComponent,
    AboutteamMembersComponent,
    GetIntouchComponent,
    GetInTouchBannerComponent,
    ContactusFormComponent,
    RefundPolicyComponent,
    TermsConditionsComponent,
    HowCredmateWorksComponent,
    HowTopBannerComponent,
    HowBodyBannerComponent,
    HowstepperBannerComponent,
    PaymentModalComponent,
    TitlePaymentModalComponent,
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MatSliderModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatAutocompleteModule,
    MatSelectModule,
    CommonModule,
    FormsModule,
    NgChartsModule,
    MatCardModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    FlexLayoutModule,
    NgxPayPalModule,
    MatCardModule,
    MatStepperModule,
    MatChipsModule,
    MatProgressBarModule
    
    
    

    
    
    
    

    // TranslateModule.forRoot(), // forRoot used for root module configuration
    // NgxBootstrapModule,
    // OrdersModule,
    // UserModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
