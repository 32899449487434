<mat-card class="tagLineBox">
  <h2><b>Benefits of Credmate.</b></h2>
</mat-card>
<mat-card class="benefits-container">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start">
    <div fxFlex="35" fxFlex.lt-md="100" class="benefits-box">
      <div class="heading">For Lenders:</div>
      <mat-list>
        <mat-list-item>
          <div matLine>Borrower Analysis</div>
          <p matLine>
            Analyze past repayment promise keeping behavior of borrower/buyer
            before grant of credit
          </p>
        </mat-list-item>
        <mat-list-item>
          <div matLine>Realtime Reports</div>
          <p matLine>Check realtime market position of borrower & buyer</p>
        </mat-list-item>
        <mat-list-item>
          <div matLine>Defaulter Awareness</div>
          <p matLine>
            Inbuilt Timeline to create awareness about the Promises Not
            completed
          </p>
        </mat-list-item>
        <mat-list-item>
          <div matLine>Credit Sales Record</div>
          <p matLine>
            Ability to record promises against credit-based sales of goods (B2B)
          </p>
        </mat-list-item>
      </mat-list>
    </div>

    <div fxFlex="30" fxFlex.lt-md="100" class="text-center img-container">
      <img src="assets/images/Benefits.jpg" class="img-fluid" />
    </div>

    <div fxFlex="35" fxFlex.lt-md="100" class="benefits-box">
      <div class="heading">For Borrowers:</div>
      <mat-list>
        <mat-list-item>
          <div matLine>Consent-based Lending</div>
          <p matLine>
            Promise request initiated by borrower himself proves consent
          </p>
        </mat-list-item>
        <mat-list-item>
          <div matLine>Cash Transactions</div>
          <p matLine>Promises against cash repayments can also be recorded</p>
        </mat-list-item>
        <mat-list-item>
          <div matLine>Instant Credmate Score</div>
          <p matLine>Realtime updation of promise fulfillment status</p>
        </mat-list-item>
        <mat-list-item>
          <div matLine>Secure & Paperless</div>
          <p matLine>Smooth registration process with zero paperwork</p>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</mat-card>

