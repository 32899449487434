<mat-card class="tagLineBox">
    <h2>We don’t provide loans, <b>we provide trust.</b></h2>
</mat-card>

<div class="container">
    <div class="content-wrapper">
      <div class="stepper-container">
        <mat-stepper orientation="vertical" #stepper (selectionChange)="onStepSelected($event)">
        
          <!-- Step 1: User Registration -->
          <mat-step (mouseover)="showImage(0)" (mouseout)="hideImage()">
            <ng-template matStepLabel>User Registration</ng-template>
            <div class="step-content">
              <p>The user registers on the app with basic details such as photo, name, and mobile number.</p>
              <div>
                <button mat-button matStepperNext>Next</button>
              </div>
            </div>
          </mat-step>
          
          <!-- Step 2: Request Promise -->
          <mat-step (mouseover)="showImage(1)" (mouseout)="hideImage()">
            <ng-template matStepLabel>Request Promise</ng-template>
            <div class="step-content">
              <p>User requests promise by searching for a known lender on the app using phone number, contacts, or by scanning QR. User then selects the amount and date & time of promise fulfillment.</p>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext>Next</button>
              </div>
            </div>
          </mat-step>
          
          <!-- Step 3: Lender Notification -->
          <mat-step (mouseover)="showImage(2)" (mouseout)="hideImage()">
            <ng-template matStepLabel>Lender Notification</ng-template>
            <div class="step-content">
              <p>Lender gets the notification of promise request and views the borrower’s promise request and profile. By looking at the credit score along with the history of a user's promises, the lender can make the right decision by assessing the trustworthiness of the borrower.</p>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext>Next</button>
              </div>
            </div>
          </mat-step>
          
          <!-- Step 4: Fulfilling Promise -->
          <mat-step (mouseover)="showImage(3)" (mouseout)="hideImage()">
            <ng-template matStepLabel>Fulfilling Promise</ng-template>
            <div class="step-content">
              <p>Once the promise has been fulfilled, the borrower can close the record by entering the OTP received from the lender. The user can then choose to delete the record forever from the database.</p>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext>Next</button>
              </div>
            </div>
          </mat-step>
          
          <!-- Step 5: Profile Completion -->
          <mat-step (mouseover)="showImage(4)" (mouseout)="hideImage()">
            <ng-template matStepLabel>Profile Completion</ng-template>
            <div class="step-content">
              <p>To increase chances of promise acceptance rate, the user can fill complete profile details and opt for e-KYC authentication.</p>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext>Next</button>
              </div>
            </div>
          </mat-step>
          
          <!-- Step 6: Completion -->
          <mat-step (mouseover)="showImage(5)" (mouseout)="hideImage()">
            <ng-template matStepLabel>Completion</ng-template>
            <div class="step-content">
              <p>This process is completely paperless and secured with end-to-end encryption.</p>
              <div>
                <button mat-button matStepperPrevious>Back</button>
              </div>
            </div>
          </mat-step>
          
        </mat-stepper>
      </div>
      
      <!-- Image Container -->
      <div class="image-container">
        <ng-container *ngFor="let image of images; let i = index">
          <img *ngIf="i === activeImageIndex" [src]="image.src" [alt]="image.alt" class="step-img">
        </ng-container>
      </div>

    </div>
  </div>
  