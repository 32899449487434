<div class="mission-container">
    <div class="mission-title"><h2>Our Subscriptions <b> Plan </b></h2></div>
    <div class="mission-text">
        Unlock a world of premium features with our subscription! Select a plan that suits your needs and elevate your experience. Enjoy exclusive benefits and enhanced support. Subscribe now and make the most of every feature!
    </div>
  
    <mat-card-actions>
      <button mat-button color="primary">Learn More</button>
    </mat-card-actions>
  </div>
  