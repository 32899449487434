import { Component } from '@angular/core';

@Component({
  selector: 'app-home-bottom-banner',
  templateUrl: './home-bottom-banner.component.html',
  styleUrls: ['./home-bottom-banner.component.scss']
})
export class HomeBottomBannerComponent {
  steps = [
    { number: 1, description: 'Download Credmate app on your device' },
    { number: 2, description: 'Complete registration and authentication' },
    { number: 3, description: 'Search for user and send promise request' },
    { number: 4, description: 'You’re good to go!' }
  ];
}
