<div fxLayout="row wrap" fxLayoutGap="16px grid">
  <div
    fxFlex="100%"
    fxFlex.gt-sm="50%"
    fxFlex.gt-md="33%"
    fxFlex.gt-lg="25%"
    *ngFor="let plan of plans"
  >
    <mat-card class="mat-elevation-z4 h-100">
      <div class="image-container">
        <img mat-card-image [src]="plan.image" [alt]="plan.name" />
      </div>
      <mat-card-content>
        <h5 class="mat-card-title">{{ plan.name }}</h5>
        <div *ngFor="let feature of plan.features" class="feature">
          <p class="mat-card-text">{{ feature }}</p>
        </div>
      </mat-card-content>
      <div class="price-container">
        <h3 class="mat-card-title">{{ plan.price }}</h3>
      </div>
      <mat-card-actions class="action-container">
        <button
          mat-raised-button
          color="primary"
          class="buy-button"
          [disabled]="isItemAddedToCart"
          (click)="addToCart(plan)"
        >
          <mat-icon>shopping_cart</mat-icon> Proceed to Buy
        </button>
      </mat-card-actions>
      
      
    </mat-card>
  </div>
</div>
