<mat-card class="container">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="16px">
      <mat-card class="step-card ArrowBx" *ngFor="let step of steps">
        <mat-card-header>
          <mat-card-title>
            <span class="step-number">{{ step.number }}</span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>{{ step.description }}</p>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card>
  