import { Component } from "@angular/core";
import { SessionServiceService } from "src/app/core/@utility/session/session-service.service";
import { ProductCartServiceService } from "src/app/core/cart/product-cart-service.service";
import { Subscription } from "rxjs";
import { ICreateOrderRequest, IPayPalConfig } from "ngx-paypal";

interface Plan {
  id: number;
  name: string;
  features: string[];
  price: string;
  Currency: string;
  BilledAmt: number;
  image: string;
  link: string;
}

@Component({
  selector: "app-subscription-premium",
  templateUrl: "./subscription-premium.component.html",
  styleUrls: ["./subscription-premium.component.scss"],
})
export class SubscriptionPremiumComponent {

  sessionId: string | null;
  isItemAddedToCart = false;
  private currentlyAddedItem: any;
  private subscription!: Subscription;
  
  public payPalConfig?: IPayPalConfig;

  isMiddleCard(index: number): boolean {
    return index === Math.floor(this.plans.length / 2);
  }


  plans: Plan[] = [
    {
      id: 1,
      name: "Basic Plan",
      features: [
        "Transactions - 150",
        "Allowed Profile Search - 30",
        "Proof Verification - One Govt Id",
      ],
      price: "C$ 6.5 Annual",
      Currency: "CAD",
      BilledAmt: 6.5,
      image: "assets/items/BasicPlan.png",
      link: "https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=sb-uwnxk27174273@business.example.com&item_name=BasicPlan&amount=100.00&currency_code=CAD",
    },
    {
      id: 2,
      name: "Business Plan",
      features: [
        "Transactions - 500",
        "Allowed Profile Search - 30",
        "Proof Verification - Two Govt Id",
      ],
      price: "C$ 500 Annual",
      Currency: "CAD",
      BilledAmt: 500,
      image: "assets/items/PremiumPlan.png",
      link: "https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=sb-uwnxk27174273@business.example.com&item_name=BusinessPlan&amount=300.00&currency_code=CAD",
    },
    {
      id: 3,
      name: "Enterprise Plan",
      features: [
        "Transactions - 100K",
        "Allowed Profile Search - 30",
        "Proof Verification - Two Govt Id",
        "Physical Verification",
      ],
      price: "C$ 5000 Annual",
      Currency: "CAD",
      BilledAmt: 5000,
      image: "assets/items/EnterprisePlan.png",
      link: "https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=sb-uwnxk27174273@business.example.com&item_name=EnterprisePlan&amount=3000.00&currency_code=CAD",
    },
  ];

  constructor(
    private sessionService: SessionServiceService,
    private cartService: ProductCartServiceService
  ) {
    this.sessionId = this.sessionService.getSessionId();
  }
  ngOnInit() {
    this.subscription = this.cartService.itemRemoved.subscribe(
      (removedItem: any) => {
        // Check whether the removedItem is the one that was added, and update isItemAddedToCart accordingly
        if (this.isTheRemovedItem(removedItem)) {
          this.isItemAddedToCart = false;
        }
      }
    );
  
    this.subscription = this.cartService.getCartItems().subscribe(cartItems => {
      this.isItemAddedToCart = this.plans.some(plan => 
        cartItems.some(item => item.id === plan.id)
      );
    });
    
  }




  addToCart(plan: Plan) {
    this.isItemAddedToCart = true;
    // Implement the logic to add the selected plan to the cart
    // and navigate to the PayPal link

    this.currentlyAddedItem = plan; // Set the currently added item

    this.cartService.addItemToCart(plan);
  }

  private isTheRemovedItem(removedItem: any): boolean {
    // Check whether removedItem and this.currentlyAddedItem are non-null before accessing their id property
    if (removedItem && this.currentlyAddedItem && removedItem.id === this.currentlyAddedItem.id) {
      return true;
    }
    return false;
  }
  
}
