import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductCartServiceService {
  private cartItems = new BehaviorSubject<any[]>(this.getStoredCartItems());
  public itemRemoved = new BehaviorSubject<any>(null);


  getCartItems() {
    return this.cartItems.asObservable();
  }
  addItemToCart(item: any) {
    const updatedItems = [...this.cartItems.getValue(), item];
    this.cartItems.next(updatedItems);
    this.storeCartItems(updatedItems);
  }

  removeItemFromCart(itemToRemove: any) {
    const currentItems = this.cartItems.getValue();
    const updatedItems = currentItems.filter(item => item !== itemToRemove);
    this.cartItems.next(updatedItems);
    this.storeCartItems(updatedItems);
    this.itemRemoved.next(itemToRemove);
  }

  private getStoredCartItems(): any[] {
    const storedItems = localStorage.getItem('cartItems');
    return storedItems ? JSON.parse(storedItems) : [];
  }

  private storeCartItems(items: any[]): void {
    localStorage.setItem('cartItems', JSON.stringify(items));
  }

}
