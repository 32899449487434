import { Component } from '@angular/core';

@Component({
  selector: 'app-home-middlebar',
  templateUrl: './home-middlebar.component.html',
  styleUrls: ['./home-middlebar.component.scss']
})
export class HomeMiddlebarComponent {

}
