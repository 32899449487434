<!-- Top Section -->
<div class="mat-elevation-z4 drkRedbg">
  <div class="container redBx">
    <div fxLayout="column" fxLayoutAlign="center center" class="action-container">
      <span class="whitetitle">Enter into the new world of trust with Credmate. Download now!</span>
      <div class="StorebtnRdBx" fxLayout="column" fxLayoutAlign="center center">
        <div>
          <a href="https://play.google.com/store/apps/details?id=in.credmate">
            <img src="assets/images/PlayStore-min.png" class="StrBtn">
          </a>
        
          <a href="https://apps.apple.com/in/app/credmate/id1545373367">
            <img src="assets/images/AppStore.png" class="StrBtn">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

  
  <!-- Footer Section -->
  <div class="mat-elevation-z4 lightGraybg">
    <div class="container footerPad">
      
      <!-- Logo and Email -->
      <div fxLayout="column" fxLayoutAlign="center center" class="action-container">
        <div class="Ftrlogo">
            <img src="assets/images/footerlogo.jpg" style="width: 170px;" class="StrBtn">
        </div>
        
        <div class="Ftremail">
            <a href="mailto:support@credmate.ca" target="_blank">support@credmate.ca</a>
        </div>
    </div>
    
      
      <!-- Footer Menu -->
      <div fxLayout="row" fxLayoutAlign="center center" class="action-container FtrMenu">
        <nav>
          <a mat-button routerLink="/about">About</a>
          <a mat-button routerLink="/how-credmate-works">How It Works</a>
          <a mat-button routerLink="/get-in-touch">Get In Touch</a>
          <a mat-button routerLink="/terms-and-conditions">Terms & Conditions</a>
          <a mat-button routerLink="/refund-policy">Refund Policy</a>
        </nav>
      </div>
      
      <!-- Copyright and Social Media -->
      <div fxLayout="row" fxLayoutAlign="space-between center" class="action-container copyBx">
        
        <div class="footer1">
          <!-- Social Media Icons -->
          <a href="https://www.youtube.com/channel/UCAY5yvCTVaYd09ntxoUDRUQ">
            <img src="assets/images/social-icon1.png">
          </a>
          <a href="https://www.facebook.com/thecredmate/">
            <img src="assets/images/social-icon2.png">
          </a>
          <a href="https://www.instagram.com/thecredmate/">
            <img src="assets/images/social-icon3.png">
          </a>
          <a href="https://in.linkedin.com/company/credmate">
            <img src="assets/images/social-icon4.png">
          </a>
        </div>
        
        <div class="footer2">© 2023 Credmate. All rights reserved.</div>
        
        <div class="footer3">
          <!-- App Store Icons -->
          <div>
            <a href="https://play.google.com/store/apps/details?id=in.credmate">
              <img src="assets/images/PlayStore-min.png" class="StrBtn">
            </a>
          
            <a href="https://apps.apple.com/in/app/credmate/id1545373367">
              <img src="assets/images/AppStore.png" class="StrBtn">
            </a>
          </div>
        </div>
        
      </div>
      
    </div>
  </div>
  