import { AuthchildGuard } from './core/@auth/authchild.guard';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutComponent } from './modules/shared/payment/checkout/checkout.component';
import { SubscriptionBasicComponent } from './modules/shared/payment/checkout/subscription-basic/subscription-basic.component';
import { SubscriptionPremiumComponent } from './modules/shared/payment/checkout/subscription-premium/subscription-premium.component';
import { HomeComponent } from './modules/shared/home/home.component';
import { AboutComponent } from './modules/shared/about/about.component';
import { GetIntouchComponent } from './modules/shared/get-intouch/get-intouch.component';
import { RefundPolicyComponent } from './modules/shared/other/refund-policy/refund-policy.component';
import { TermsConditionsComponent } from './modules/shared/other/terms-conditions/terms-conditions.component';
import { HowCredmateWorksComponent } from './modules/shared/how-credmate-works/how-credmate-works.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: HomeComponent},
  { path: 'about', component: AboutComponent},
  { path: 'get-in-touch', component: GetIntouchComponent},
  { path: 'how-credmate-works', component: HowCredmateWorksComponent},
  { path: 'refund-policy', component: RefundPolicyComponent},
  { path: 'terms-and-conditions', component: TermsConditionsComponent},
  { path: 'checkout', component: CheckoutComponent,
  children: [
    {
      path: 'basic',
      component: SubscriptionBasicComponent,
    },
    {
      path: 'premium',
      component: SubscriptionPremiumComponent,
    },
    {
      path: '', // empty path represents the default route
      redirectTo: 'premium', // redirect to enterprise plan
      pathMatch: 'full', // it is necessary to set pathMatch to full for redirecting
    }
  ], },
  
  { path: 'user', loadChildren: () => import('./modules/login-module/login-module.module').then(m => m.LoginModuleModule) },
  { path: 'lead', loadChildren: () => import('./modules/lead-management/lead-management.module').then(m => m.LeadManagementModule) },
  { path: 'auth', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
  
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
