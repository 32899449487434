<mat-toolbar color="primary">
    <span class="logo-container">
      <a routerLink="/">
        <img src="assets/logo/logo.png" alt="" class="logo-image">
      </a>
    </span>
  
    <span class="spacer"></span>
  
    <!-- Dropdown menu for mobile view -->
    <div class="dropdown show-on-mobile">
      <button mat-button (click)="showDropdown = !showDropdown">
          <div class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
          </div>
      </button>
      <div class="dropdown-content" [style.display]="showDropdown ? 'block' : 'none'">
        <button mat-button routerLink="/about"  (click)="showDropdown = false">About</button>
        <button mat-button routerLink="/how-credmate-works"  (click)="showDropdown = false">How It Works</button>
        <button mat-button routerLink="/checkout"  (click)="showDropdown = false">Subscription</button>
        <button mat-button routerLink="/get-in-touch"  (click)="showDropdown = false">Get In Touch</button>
        
        
      </div>
    </div>
  
    <!-- Buttons for desktop view -->
    <div class="hide-on-mobile">
      <a mat-button routerLink="/about" rel="noopener noreferrer" (click)="showDropdown = false">About</a>
      <a mat-button routerLink="/how-credmate-works" rel="noopener noreferrer" (click)="showDropdown = false">How It Works</a>
      <a mat-button routerLink="/checkout" rel="noopener noreferrer" (click)="showDropdown = false">Subscription</a>
      <a mat-button routerLink="/get-in-touch" rel="noopener noreferrer" (click)="showDropdown = false">Get In Touch</a>
      
  </div>
  
  </mat-toolbar>
  