import { Component } from '@angular/core';

@Component({
  selector: 'app-subscription-banner',
  templateUrl: './subscription-banner.component.html',
  styleUrls: ['./subscription-banner.component.scss']
})
export class SubscriptionBannerComponent {

}
