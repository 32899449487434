<app-top-menubar></app-top-menubar>
<app-subscription-basic></app-subscription-basic>
<app-subscription-banner></app-subscription-banner>

<div class="main-container">

  <div class="left-container">
    <router-outlet></router-outlet>
  </div>
  <div class="right-container">
    
    <app-review-cart></app-review-cart>
    <br>
    <div class="payment-container">
      <mat-card class="payment-card">
        <mat-card-header>
          <mat-card-title>Complete Your Purchase</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>payment</mat-icon> Pay with PayPal
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>Pay securely using your PayPal account or credit/debit card.</p>
              <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

</div>

<app-common-footer></app-common-footer>