import { Component } from '@angular/core';

@Component({
  selector: 'app-home-tope-banner',
  templateUrl: './home-tope-banner.component.html',
  styleUrls: ['./home-tope-banner.component.scss']
})
export class HomeTopeBannerComponent {

}
