import { Component } from '@angular/core';

@Component({
  selector: 'app-abouttop-banner',
  templateUrl: './abouttop-banner.component.html',
  styleUrls: ['./abouttop-banner.component.scss']
})
export class AbouttopBannerComponent {

}
