import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductCartServiceService } from 'src/app/core/cart/product-cart-service.service';



@Component({
  selector: 'app-review-cart',
  templateUrl: './review-cart.component.html',
  styleUrls: ['./review-cart.component.scss']
})
export class ReviewCartComponent implements OnDestroy {
  cartItems: any[] = [];
  private subscription: Subscription;
  

  constructor(private cartService: ProductCartServiceService) {
    this.subscription = this.cartService.getCartItems().subscribe(items => {
      this.cartItems = items;
      console.log(items)
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  
  onRemoveFromCart(item: any) {
    this.cartService.removeItemFromCart(item);
  }

  getTotal() {
    const total = this.cartItems.reduce((acc, item) => acc + item.BilledAmt, 0);
    
    return total;
  }
  


}
