
<mat-card class="tagLineBox">
    <h2>We don’t provide loans, <b>we provide trust.</b></h2>
</mat-card>

<div class="container">
    <mat-stepper orientation="vertical" #stepper>
  
      <mat-step>
        <ng-template matStepLabel>User Registration</ng-template>
        <div class="step-content">
          <div class="step-info">
            <p>The user registers on the app with basic details such as photo, name, and mobile number.</p>
            <div>
              <button mat-button matStepperNext>Next</button>
            </div>
          </div>
          <img src="./assets/images/5.png" class="w-80" alt="User Registration">
        </div>
      </mat-step>
  
      <mat-step>
        <ng-template matStepLabel>Request Promise</ng-template>
        <div class="step-content">
          <div class="step-info">
            <p>User requests promise by searching for a known lender on the app using phone number, contacts, or by scanning QR. User then selects the amount and date & time of promise fulfillment.</p>
            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext>Next</button>
            </div>
          </div>
          <img src="./assets/images/2.png" class="w-80" alt="Request Promise">
        </div>
      </mat-step>
  
      <mat-step>
        <ng-template matStepLabel>Lender Notification</ng-template>
        <div class="step-content">
          <div class="step-info">
            <p>Lender gets the notification of promise request and views the borrower’s promise request and profile. By looking at the credit score along with the history of a user's promises, the lender can make the right decision by assessing the trustworthiness of the borrower.</p>
            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext>Next</button>
            </div>
          </div>
          <img src="./assets/images/3.png" class="w-80" alt="Lender Notification">
        </div>
      </mat-step>
  
      <mat-step>
        <ng-template matStepLabel>Fulfilling Promise</ng-template>
        <div class="step-content">
          <div class="step-info">
            <p>Once the promise has been fulfilled, the borrower can close the record by entering the OTP received from the lender. The user can then choose to delete the record forever from the database.</p>
            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext>Next</button>
            </div>
          </div>
          <img src="./assets/images/4.png" class="w-80" alt="Fulfilling Promise">
        </div>
      </mat-step>
  
      <mat-step>
        <ng-template matStepLabel>Profile Completion</ng-template>
        <div class="step-content">
          <div class="step-info">
            <p>To increase chances of promise acceptance rate, the user can fill complete profile details and opt for e-KYC authentication.</p>
            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext>Next</button>
            </div>
          </div>
          <img src="./assets/images/1.png" class="w-80" alt="Profile Completion">
        </div>
      </mat-step>
  
      <mat-step>
        <ng-template matStepLabel>Completion</ng-template>
        <div class="step-content">
          <div class="step-info">
            <p>This process is completely paperless and secured with end-to-end encryption.</p>
            <div>
              <button mat-button matStepperPrevious>Back</button>
            </div>
          </div>
          <img src="./assets/images/6.png" class="w-80" alt="Completion">
        </div>
      </mat-step>
  
    </mat-stepper>
  </div>
  

<div class="container">
    <mat-stepper orientation="vertical" linear #stepper>
      <mat-step>
        <ng-template matStepLabel>User Registration</ng-template>
        <div class="step-content">
          <p>The user registers on the app with basic details such as photo, name, and mobile number.</p>
          <img src="./assets/images/1.png" class="w-80" alt="User Registration">
          <div>
            <button mat-button matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      
      <mat-step>
        <ng-template matStepLabel>Request Promise</ng-template>
        <div class="step-content">
          <p>User requests promise by searching for a known lender on the app using phone number, contacts, or by scanning QR. User then selects the amount and date & time of promise fulfillment.</p>
          <img src="./assets/images/2.png" class="w-80" alt="Request Promise">
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
  
      <mat-step>
        <ng-template matStepLabel>Lender Notification</ng-template>
        <div class="step-content">
          <p>Lender gets the notification of promise request and views the borrower’s promise request and profile. By looking at the credit score along with the history of a user's promises, the lender can make the right decision by assessing the trustworthiness of the borrower.</p>
          <img src="./assets/images/3.png" class="w-80" alt="Lender Notification">
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
  
      <mat-step>
        <ng-template matStepLabel>Fulfilling Promise</ng-template>
        <div class="step-content">
          <p>Once the promise has been fulfilled, the borrower can close the record by entering the OTP received from the lender. The user can then choose to delete the record forever from the database.</p>
          <img src="./assets/images/4.png" class="w-80" alt="Fulfilling Promise">
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
  
      <mat-step>
        <ng-template matStepLabel>Profile Completion</ng-template>
        <div class="step-content">
          <p>To increase chances of promise acceptance rate, the user can fill complete profile details and opt for e-KYC authentication.</p>
          <img src="./assets/images/5.png" class="w-80" alt="Profile Completion">
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
  
      <mat-step>
        <ng-template matStepLabel>Completion</ng-template>
        <div class="step-content">
          <p>This process is completely paperless and secured with end-to-end encryption.</p>
          <img src="./assets/images/6.png" class="w-80" alt="Completion">
          <div>
            <button mat-button matStepperPrevious>Back</button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  
