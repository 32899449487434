<mat-card class="chat-card custom-dialog-card">
    <!-- ... (other card content) ... -->
  
    <app-title-payment-modal></app-title-payment-modal>
  
    <mat-card class="chat-card">
      <mat-card-header>
        <div mat-card-avatar class="chat-header-image"></div>
        <mat-card-subtitle>Agent</mat-card-subtitle>
      </mat-card-header>
  
      <mat-card-content>
        <h2>Payment Status</h2>
        <p>{{ status }}</p>
      </mat-card-content>
    </mat-card>
  </mat-card>


  <mat-card-footer>
  
  </mat-card-footer>

  
  <mat-card-actions align="end">
    <button mat-button>Close</button>
  </mat-card-actions>
  