import { Component } from '@angular/core';

@Component({
  selector: 'app-how-credmate-works',
  templateUrl: './how-credmate-works.component.html',
  styleUrls: ['./how-credmate-works.component.scss']
})
export class HowCredmateWorksComponent {

}
