import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface Step {
  content: string;
  image: string;
}


@Component({
  selector: 'app-how-body-banner',
  templateUrl: './how-body-banner.component.html',
  styleUrls: ['./how-body-banner.component.scss']
})
export class HowBodyBannerComponent {
  


}
