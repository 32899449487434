<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutAlign="center center" class="container-fluid lightGraybg">
    <mat-card fxFlex="70" fxFlex.lt-md="100" class="borderless-card">
        <mat-card-title>
          <div class="h3 englishHead">A New World of <b>Trust</b></div>
          <p>Credmate aims to make money lending fraud-free by providing lenders and borrowers with a trust protocol.</p>
        </mat-card-title>
        <mat-card-actions class="d-flex">
          <a href="https://play.google.com/store/apps/details?id=in.credmate" mat-button>
            <img src="assets/images/Play_Store_Badge.svg" alt="Play Store Badge">
          </a>
          <a href="https://apps.apple.com/in/app/credmate/id1545373367" mat-button>
            <img src="assets/images/App_Store_Badge.svg" alt="App Store Badge">
          </a>
        </mat-card-actions>
      </mat-card>
      
  
    <div fxFlex="70" fxFlex.lt-md="100" class="text-center">
      <img src="assets/images/Hero-img.png" class="img-fluid" style="height:382px;">
    </div>
  </div>
  
