import { Component } from '@angular/core';

@Component({
  selector: 'app-how-top-banner',
  templateUrl: './how-top-banner.component.html',
  styleUrls: ['./how-top-banner.component.scss']
})
export class HowTopBannerComponent {

}
