import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthpopupComponent } from 'src/app/modules/login-module/authpopup/authpopup.component';


@Component({
  selector: 'app-top-menubar',
  templateUrl: './top-menubar.component.html',
  styleUrls: ['./top-menubar.component.scss']
})
export class TopMenubarComponent {
  constructor(public dialog: MatDialog) {}
  showDropdown = false;
  

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  openDialog() {
    const dialogRef = this.dialog.open(AuthpopupComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
