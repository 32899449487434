import { Component } from '@angular/core';

@Component({
  selector: 'app-get-intouch',
  templateUrl: './get-intouch.component.html',
  styleUrls: ['./get-intouch.component.scss']
})
export class GetIntouchComponent {

}
