<mat-card class="contact-container">
  <mat-card-content fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
    <div fxFlex="50" class="text-center">
      <p>
        If you have any questions or concerns, please don't hesitate to reach
        out to us. We're here to help and make sure that your experience with us
        is a positive one.
      </p>

      <div class="emailBx">
        <img src="assets/images/email.png" />
        <br />
        <a href="mailto:support@credmate.ca">support@credmate.ca</a>
      </div>
      <br />

      <a
        mat-raised-button
        style="background-color: #075e54; color: white; border-radius: 25px"
        href="https://wa.me/+14379940188"
        target="_blank"
      >
        Connect on
        <img
          src="assets/images/WhatsApp.png"
          alt="WhatsApp Logo"
          style="margin-left: 5px; vertical-align: middle"
        />
        WhatsApp
      </a>

      <br />
      <br />

      <div class="socialBx">
        <!-- Social Media Links -->
        <a href="https://www.youtube.com/channel/UCAY5yvCTVaYd09ntxoUDRUQ"
          ><img src="assets/images/social-icon1.png"
        /></a>
        <a href="https://www.facebook.com/thecredmate/"
          ><img src="assets/images/social-icon2.png"
        /></a>
        <a href="https://www.instagram.com/thecredmate/"
          ><img src="assets/images/social-icon3.png"
        /></a>
        <a href="https://in.linkedin.com/company/credmate"
          ><img src="assets/images/social-icon4.png"
        /></a>
      </div>
    </div>



    <div fxFlex="50" class="fancy-form-container mat-elevation-z4">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="form-padding">
            <mat-card class="tagLineBox">
                <h2>Contact  <b>Us</b></h2>
            </mat-card>
            <br>
            
            
            <!-- Name Field -->
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" required />
                <mat-icon matSuffix>person</mat-icon>
            </mat-form-field>
            
            <!-- Phone Field -->
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Phone</mat-label>
                <input matInput formControlName="phone" required />
                <mat-icon matSuffix>phone</mat-icon>
            </mat-form-field>
            
            <!-- Email Field -->
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Email</mat-label>
                <input matInput type="email" formControlName="email" required />
                <mat-icon matSuffix>email</mat-icon>
            </mat-form-field>
            
            <!-- Message Field -->
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Message</mat-label>
                <textarea matInput formControlName="message"></textarea>
                <mat-icon matSuffix>message</mat-icon>
            </mat-form-field>
            
            <!-- Captcha and Submit Button -->
            <div fxLayout="row" fxLayoutGap="16px" class="captcha-container">
                <div fxFlex="50">
                    <!-- Your Captcha Implementation -->
                </div>
                <div fxFlex="50" fxLayoutAlign="centre">
                    <button mat-raised-button type="submit" style="background-color: #59012D; color: white; border-radius: 25px" matRipple class="submit-button">
                        Submit
                    </button>
                </div>
            </div>
            
            <!-- Privacy Policy -->
            <p class="privacy-policy">
                Credmate is committed to protecting your information. Your information will be used in accordance with the applicable data privacy law, our internal policies, and our privacy policy.
            </p>
        </form>
    </div>

    
  </mat-card-content>
</mat-card>
