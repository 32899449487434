<mat-card class="benefits-container">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start">
    <div fxFlex="50" fxFlex.lt-md="100" class="benefits-box text-center">
      <!-- Applying text-center here -->
      <img src="assets/images/SharkTankIndia.jpg" class="img-shark" />
      <br />
      <br />
      <div class="heading-container">
        <!-- New div for flexbox -->
        <span class="heading">As seen on Shark Tank India</span>
      </div>
    </div>

    <div fxFlex="50" fxFlex.lt-md="100" class="benefits-box">
        <a href="https://www.youtube.com/watch?v=_DJaaBVRuu0" target="_blank" rel="noopener noreferrer">
            <img src="assets/images/Credmate_Promo.jpg" class="responsive-img" />
        </a>
    </div>
    
  </div>
</mat-card>


<mat-card class="tagLineBox">
    <h2><b>Get started on Credmate in 4 simple steps</b></h2>
</mat-card>

