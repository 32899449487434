import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit {
  receivedData: any;
  cartItems: any[];
  status: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.receivedData = data.receivedData;
    this.cartItems = data.cartItems;
    this.status = data.status;
  }
  ngOnInit(): void {
      
  }

  







}
