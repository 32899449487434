
<!-- cart.component.html -->
<div class="cart-container">
    <div class="cart-header">
        <h3>Your Cart</h3>
        <hr />
    </div>
    <div *ngFor="let item of cartItems" class="cart-item">
        <div class="item-details">
            <h4>{{ item.name }} | <h4>{{ item.price }}</h4></h4>
            
        </div>
        <button class="remove-btn" (click)="onRemoveFromCart(item)">
            <i class="fa fa-trash" aria-hidden="true"></i> Remove
        </button>
    </div>
    <div *ngIf="cartItems.length === 0" class="empty-cart">
        <p>Your cart is empty!</p>
    </div>
    <div class="cart-total">
        <hr />
        <h4>Total: C${{ getTotal() ? getTotal().toFixed(2) : '0.00' }}</h4>
    </div>
</div>
