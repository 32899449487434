<mat-card class="redRoundBox">
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center center">
        <div fxFlex="33" class="statsBox">
            <span class="large-text">500k+</span>
            <p>Total Users</p>
        </div>
        <div fxFlex="33" class="statsBox">
            <span class="large-text">C$ 300+ Million</span>
            <p>Worth Promises</p>
        </div>
        <div fxFlex="33" class="statsBox">
            <span class="large-text">98%</span>
            <p>Fraud Reduction</p>
        </div>
    </div>
</mat-card>

<mat-card class="tagLineBox">
    <h2>We don’t provide loans, <b>We provide trust.</b></h2>
</mat-card>


<mat-card fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" class="impact-container">
   
    <div fxFlex="60" fxFlex.lt-md="100" class="text-container">
        <span class="titles">How Credmate is making an impact</span>
        <p>Credmate is poised to disrupt the traditional lending industry and revolutionize the way people access credit. We are working hard to instigate fraud-free promises between lender-borrower & buyer-seller in the world. Especially where informal promises are done for repayments of loans or credit sales without substantial consent and formal promises.</p>
        <p>In the rapid evolving digital landscape, Credmate acts as a trust protocol for the community to ensure that no one falls prey to frauds due to lack of sufficient information about defaulters not keeping their promises.</p>
    </div>
    <div fxFlex="40" fxFlex.lt-md="100" class="text-center img-container">
        <img src="assets/images/bodyImg.jpg" class="img-fluid">
    </div>
</mat-card>

