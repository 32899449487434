import { Component } from '@angular/core';


interface TeamMember {
  name: string;
  role: string;
  image: string;
  linkedin: string;
}

@Component({
  selector: 'app-aboutteam-members',
  templateUrl: './aboutteam-members.component.html',
  styleUrls: ['./aboutteam-members.component.scss']
})
export class AboutteamMembersComponent {
  teamMembers: TeamMember[] = [
    {
      name: 'Gaurav Kundra',
      role: 'Co-Founder & CIO',
      image: 'assets/images/GauravKundra.jpg',
      linkedin: 'https://www.linkedin.com/in/gaurav-kundra-493207113/'
    },
    {
      name: 'Gaurav Sharma',
      role: 'Co-Founder & CEO',
      image: 'assets/images/GauravSharma.jpg',
      linkedin: 'https://www.linkedin.com/in/gaurav-sharma-19155696/'
    },
    {
      name: 'Vivek Siddhartha',
      role: 'CTO',
      image: 'assets/images/VivekSiddhartha.png',
      linkedin: 'https://www.linkedin.com/in/vivek-siddhartha-ab157b14/'
    },
    {
      name: 'Sanjay Kathuria',
      role: 'CFA',
      image: 'assets/images/SanjayKathuria.jpg',
      linkedin: 'https://www.linkedin.com/in/sanjaykathuria'
    },
    {
      name: 'Ishant Kumar',
      role: 'Product Owner',
      image: 'assets/images/Ishant.jpeg',
      linkedin: 'https://www.linkedin.com/in/kumarishant/'
    }
  ];


}
