<mat-card class="chat-card custom-dialog-card">
    <app-auth-header></app-auth-header>

    <mat-card class="chat-card">

        <mat-card-header>
              
            <!-- Login Form -->
            <ng-container *ngIf="!isRegistering && !isResettingPassword">
            <div mat-card-avatar class="chat-header-image"></div>
            <div class="title-bar">
                <span class="title">Login</span>
            </div>
            </ng-container>

            <!-- Registration Form -->
            <ng-container *ngIf="isRegistering">
                <div mat-card-avatar class="chat-header-image"></div>
                <div class="title-bar">
                    <span class="title">Register</span>
                </div>
                
            </ng-container>

            <!-- Reset Password Form -->
            <ng-container *ngIf="isResettingPassword">
                <div mat-card-avatar class="chat-header-image"></div>
                <div class="title-bar">
                    <span class="title">Reset Password</span>
                </div>
                
            </ng-container>

        </mat-card-header>

        <mat-card-content>
            <!-- Login Form -->
            <ng-container *ngIf="!isRegistering && !isResettingPassword">
                <form (ngSubmit)="login()">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" [(ngModel)]="email" name="email" required>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="password" name="password" required>
                        <mat-icon matSuffix (click)="togglePasswordVisibility()">
                            {{ hide ? 'visibility_off' : 'visibility' }}
                        </mat-icon>
                    </mat-form-field>

                    <button mat-raised-button color="primary" class="login-button" [disabled]="!password || !email">
                        <mat-icon>login</mat-icon>
                        <span>Login</span>
                    </button>
                </form>
            </ng-container>

            <!-- Registration Form -->
            <ng-container *ngIf="isRegistering">
                <form (ngSubmit)="register()">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput type="text" [(ngModel)]="name" name="name" required>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" [(ngModel)]="username" name="Email" required>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Type</mat-label>
                        <mat-select [(ngModel)]="type" name="Type" required>
                            <mat-option value="user">User</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="password" name="password" required>
                        <mat-icon matSuffix (click)="togglePasswordVisibility()">
                            {{ hide ? 'visibility_off' : 'visibility' }}
                        </mat-icon>
                    </mat-form-field>

                    <button mat-raised-button color="primary" class="login-button" [disabled]="!name || !username || !type || !password">
                        <mat-icon>user</mat-icon>
                        <span>Register</span>
                    </button>
                </form>
            </ng-container>

            <!-- Reset Password Form -->
            <ng-container *ngIf="isResettingPassword">
                <form (ngSubmit)="resetPassword()">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" [(ngModel)]="email" name="email" required>
                    </mat-form-field>

               

                    <button mat-raised-button color="primary" class="login-button" [disabled]="!email">
                        <mat-icon>key</mat-icon>
                        <span>Reset Password</span>
                    </button>
                </form>
            </ng-container>
        </mat-card-content>

        <mat-card-actions>
            <ng-container *ngIf="!isRegistering && !isResettingPassword">
                <p>New to us? <a (click)="isRegistering = true; isResettingPassword = false">Sign Up</a></p>
                <p><a (click)="isResettingPassword = true; isRegistering = false">Forgot Password?</a></p>
            </ng-container>
            <p *ngIf="isRegistering"><a (click)="isRegistering = false; isResettingPassword = false">Already have an account? Login</a></p>
            <p *ngIf="isResettingPassword"><a (click)="isResettingPassword = false">Back to Login</a></p>
        </mat-card-actions>
    </mat-card>
</mat-card>
