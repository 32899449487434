
<app-top-menubar></app-top-menubar>

<mat-card class="tagLineBox">
    <h2>Refund <b>Policy</b></h2>
</mat-card>

<div fxLayout="column" fxLayoutAlign="center center" class="container">
    <mat-card class="example-card">
      <mat-card-content>
        <div class="refund-section">
          <mat-card-title>
            <b>Refund</b>
          </mat-card-title>
          <p>All sales are final and no refund will be issued.</p>
        </div>
  
        <div class="questions-section">
          <mat-card-title>
            <b>Questions</b>
          </mat-card-title>
          <p>If you have any questions concerning our refund policy, please contact us at:</p>
          <mat-list>
            <mat-list-item>
              Email: <a href="mailto:info@credmate.in">info@credmate.in</a>
            </mat-list-item>
          </mat-list>
        </div>
  
        <p>Thank you for shopping at Credmate.</p>
      </mat-card-content>
    </mat-card>
  </div>
  
  <app-common-footer></app-common-footer>