<mat-card class="about-container">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="100" class="text-container">
        <p><b>Credmate</b> was founded in 2019, as a consent and promise- based mobile application platform which records the consent and promises as well as assigns a Credmate score based on promise fulfillment behaviour of the user, denoting that a user has kept his promises well or is not a disciplined user while keeping his promises, so that no one can be a next victim of that defaulting user in lack of information.</p>
      </div>
    </div>
  </mat-card>
  

<!-- team.component.html -->
<mat-card class="mat-elevation-z4 team-card-container">
    <mat-card-title class="center-text">Meet Our Team</mat-card-title>
    <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="center center" fxLayout.lt-md="column">
      <mat-card *ngFor="let member of teamMembers" class="mat-elevation-z4 team-member-card">
        <img mat-card-image [src]="member.image" alt="{{ member.name }}" class="circle-image">
        <mat-card-content class="center-text">
          <h2>{{ member.name }}</h2>
          <p>{{ member.role }}</p>
        </mat-card-content>
        <mat-card-actions class="center-text">
            <a mat-raised-button color="primary" [href]="member.linkedin" target="_blank">
              <mat-icon aria-hidden="false" aria-label="LinkedIn Icon">linkedin</mat-icon>
              View on LinkedIn
            </a>
          </mat-card-actions>
      </mat-card>
    </div>
  </mat-card>
  