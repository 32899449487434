<app-top-menubar></app-top-menubar>

<mat-card class="tagLineBox">
    <h2> <b>Terms & Conditions</b></h2>
</mat-card>
<div>
<p>We care about your privacy and are committed to protecting your personal data. Please read this privacy statement carefully before using our Services.<br>
    We at CREDMATE (“the Company/We/Us/CREDMATE”) are committed to protecting the privacy and security of your personal information. Your privacy is important to us and maintaining your trust is paramount.
    This Privacy Policy explains how we collect, use, process and disclose information about you. By using our website/app and affiliated services, you consent to the terms of our privacy policy (“Privacy Policy”) in addition to our Terms of Use. We encourage you to read this Privacy Policy regarding the collection, use, and disclosure of your information from time to time to keep yourself updated with the changes & updated that we make to this Policy.This Privacy Policy describes our privacy practices for all websites, products and services that are linked to it. Any capitalized terms not defined hereunder shall hold the same definition as provided under the Terms of Use.
    </p>
    <b>1. Information we collect</b>
    <p>The following information is collected by or on behalf of CREDMATE</p>
    <b>1.1 Information You provide us</b>
    
    <ul>
      <li>When you start using the App Services, we ask you to provide certain information as part of the registration process, and in the course of your interface with the App. We will collect this information through various means and in various places through the App Services, including account registration forms, contact us forms, or when you otherwise interact with CREDMATE including at customer support.</li>
      <li>At the time of registration/physical verification(for verified accounts), we may ask for the following personal information
        <ul>
          <li>Name (First Name, Last Name</li>
          <li>Mobile Number</li>
          <li>Email ID</li>
          <li>Date of Birth</li>
          <li>Residence Address</li>
          <li>Asset Details Gst number, Aadhar number, any government id, passport details etc</li>
          <li>Office Address</li>
          <li>PAN Card</li>
          <li>Social media details like Facebook id, twitter, snapchat,linkedin, Instagram or any other social media account details</li>
        </ul>
      </li>
      <li>Pursuant to the services consumed by You from time to time, we may explicitly seek additional information including address, payment or banking information, credit/debit card details,UPI, private digital wallets and any other governmental identification numbers or documents. You may choose to provide such information if you choose to use these services provided by the App.</li>
    </ul> 
    <b>1.2 Information created when You use our App Services</b>
    
    <ul>
      <li>We collect information relating to your use of our website/app through the use of various technologies. This includes transaction details related to your use of our services including the type of services you requested, the payment method, amount and other related transactional and financial information. Further, depending on the services used by you we may also collect the order details, payment & transaction details information etc. Further, when you visit our website/app, we may log certain information such as your IP address, browser type, mobile operating system, manufacturer and model of your mobile device, geo-location, preferred language, access time, and time spent etc. We may also collect information about the pages you view within our sites and other actions you take while visiting our website/app. From time to time, during your use of the App Services, CREDMATE may require access to certain additional information such as SMS & contact details. Prior to accessing any such additional information, explicit consent shall be sought from the User. Please note that even after consent has been provided by User, we only read transactional or promotional SMS and do not open, access or read any personal SMS.</li>
      <li>We also maintain some records of Users who contact us for support, for the purpose of responding to such queries and other related activities. However, we do not provide this information to any third party without your permission, or utilize the same for any purposes not set out hereunder</li>
    </ul>
    
    
    <b>
    1.3 Information We Collect from Third Parties or using our machine learning algorithm and how we process and use this information
    </b>
    <ul>
      <li>We may, with or without your consent, request certain third parties to provide information about you to further personalize your experience on our website/app, and provide certain services that cannot be accessed by all users of the website/app.</li>
      <li>CREDMATE is currently open to anyone who wants to record transaction using this APP . Credmate will assess the transactions recorded on APP using its intelligent machine learning technology and will rate the account as per analysis done by software and use a score based rating to rate your account’s credit worthiness and display it in form of scores/data or statistical form</li>
      <li>As a Credmate user you agree that if you have a Credmate score greater than or equal to its threshold level as determined by APP/ Lender may be granted credit by the lender on his own will. Credmate does not guarantee or does not commit that it will help you or ensure you any credit facility. It is purely lenders decision to grant credit and allow the amount of credit to borrower. The borrower in any case can not held credmate liable for grant to credit. In order to check if you meet the above eligibility, APP will analyise user’s transactions as per algorithm of APP and then publish user’s credit worthiness based on his/her nature of transactions done and profile information entered by him/her at the time of registration. This publication may be done in this APP/timeline so that other users may take reference of other user’s credit worthiness as per APP which in turn will help them making a credit decision. However credmate in any case will not be held liable for any such decision taken by the lender.</li>
      <li>As a Credmate user you agree that You as a borrower allow Credmate & lender to publish all such information regarding your behavior of transactions on Credmate APP and its timeline ,analyze such information using APP algorithm and show your credit worthiness in data or graphical form. You as a lender will not hold Credmate legally liable for any default done by the borrowers against your credit transaction. Credmate here is only showing analyzed data based on behavior of borrower/lender and in any case it do not gives guarantee against any such transaction between borrower & lender.</li>
      <li>As a credmate user you agree that The transactions are done with consent of borrower and lender after understanding the risk of transactions. Credmate will help lender by providing data in the form of credit score & graphical/statistical form in the APP and then lender can analyse the transactional behavior of borrower and reach a decision to accept or reject the requested credit.</li>
      <li>As a Credmate user you agree to the term that all the information of default done by you can be published on Credmate timeline depicting your name,business name, contact details, GST number, PAN number, address, email id, social media details, photographs, other relevant information and it can be reflected in your profile which is visible to all Credmate users. You will not held Credmate/ lender liable for any of such information published on this plateform which may cause you a loss in your goodwill, financial loss, initiate legal proceedings against you by lender</li>
      <li>As as credmate user you allow the lender to publish information regarding your default transactions with lender on its timeline and promote such publications on using any media</li>
      <li>As a credmate user you agree that Credmate will not help you in realization of your payments from your borrowers. Credmate is just helping you by showing credit worthiness of borrower/lender in form of data and graphics</li>
      <li>As a credmate user you understand and agree that if you use Credmate payment gateway to pay your debts you will be charged over and above your debt amount as per terms & conditions of the payment gateway</li>
      <li>If you choose to link and connect your email account with CREDMATE, we may access the said account for purposes including collecting your credits like transactions done on APP,credit card bill details such as total amount due, minimum amount due and due date, and such other financial and transactional information to be able to do the following:
    <ul>
      <li>Manage all your credit information at one place including reminding borrower/lender of the payment due before the due date by sending notifications in the APP or using whatsapp/sms/email or some other mode of communication</li>
      <li>Provide an insight into your spending pattern(s) to ensure you are aware of your spending and can make wiser decisions basis the same</li>
      <li>Curate specific financial/investment/other products for you based on your financial transactions, investments, and past financial behavior.</li> 
    </ul>
      </li>
      <li>CREDMATE’s access to the email account/s is authorized through the email provider’s access mechanism. If you permit the CREDMATE website/app to track your credit and credit card accounts, the CREDMATE website/app will securely store account details for each of your credit and credit card accounts, social media including your sign-in user name and authorization tokens for tracked accounts.</li>
      <li>You can choose to enable us to access one or more of your email & social media accounts by explicitly consenting to each single account separately. Please note that your consent to any of the above is purely voluntary. A user can de-link the access to their email and social media accounts any time they wish</li>
      <li>We only read emails from financial service providers including banks and credit card issuers and do not open, read or access any personal e-mails. We hereby confirm that we do not access any other personal information. For the sake of clarity, CREDMATE employs automated processes for accessing and analyzing information provided by a User; where we may need to use our algorithm to access a password-protected document utilizing information provided by you. For further information on how we process social media accounts, email and the security protocol please send us an email at<a href="mailto:info@credmate.in"></a> info@credmate.in .</li>
      <li>We may receive additional information about you, such as information to help detect fraud and safety issues, from third party service providers and/or partners, and combine it with information we have about you. We may receive information about you and your activities through partnerships, or about your experiences and interactions from our partner and networks and publish all this information in Credmate APP or website. Your default can be highlighted also using sponsored advertisement or promotion from your lender with whom you have committed the default</li>
    </ul> 
    <b>2. How We Use the Information we collect</b>
    <p>We may use, store and process the information provided by you to (1) provide, understand, improve and develop the App Services, (2) create and maintain a trusted and safe environment on CREDMATE (such as complying with our legal obligations and compliance with our policies) and (3) provide, personalise, measure and improve our products & services<br>
    The information collected through different channels, allows us to collect statistics about our website/app usage and effectiveness, personalize your experience whilst you are on our website/app, as well as customize our interactions with you and also assist in business development to enhance and expand the scope of the App Services. The following paragraphs describe in more detail how we use your personal information.</p>
    
    <b>2.1 Providing, understanding & improving App Services</b>
    <ul>
      <li>Any information provided by a User will be used solely for creating and updating your Account and processing your transaction(s) or for any other purposes for which you have granted access to such information to us, based on your interaction with the CREDMATE App.</li>
      <li>To complete a financial transaction, we may share financial information (such as credit card details or other payment mode details) provided by you with authorised third-parties, for instance, our business partners, financial teams/institutions, or postal/government authorities involved in fulfilment of said financial transactions, if any. This does not include any information collected from e-mails. In connection with a financial transaction, we may also contact you as part of our customer satisfaction surveys or for market research purposes.</li>
      <li>We may use the information collected to perform internal operations necessary to provide our services, including to troubleshoot software bugs and operational problems, to conduct data analysis, testing and research and to monitor and analyse usage and activity trends. We process this personal information for these purposes given our legitimate interest in improving the App Services.</li>
      <li>In addition to the transactional information made available to us, we may seek your consent and request access to your email account. If permitted, CREDMATE will automatically access the contents of emails on an ongoing basis for the purpose of managing complete credit and credit card lifecycle including credit and credit card statements, due date reminders, spend patterns and related rewards etc. In order to expand the scope of our App services, we may from time to time, seek additional information and financial documents such as information related to investments in equity, mutual funds and other documents. Any such collection of additional information & documents shall be subject to an explicit & purpose specific consent sought from all Users.</li>
      <li>Access to User email is sought to assist the user by collating all relevant details within the App which will include card payment due dates, statements from the applicable services etc. The CREDMATE website/app will securely store account details for each of your credit card accounts, including your sign-in user name and authorization tokens for tracked accounts. This information will be used to enable the CREDMATE website/app to automatically access your applicable credit and credit card statements to analyze, extract, and store information securely from such accounts for use in the CREDMATE website/app.</li>
      <li>We may use your personal information, created as part of payment services availed by you, to ensure that your access and use of payment services is in compliance with our legal obligations (such as anti-money laundering regulations). We may share such information, if required under due legal process, with our third party service partners and providers for a seamless experience for Users.</li>
    </ul> 
    
    <b>2.2 Safety, security and resolution of issues</b>
    <ul>
      <li>We may use the information to create and maintain a safe environment and use the same to detect and prevent fraud, span, abuse, security incidents and other harmful activity.</li>
      <li>We use the information we collect (including recordings of customer support calls) to assist you when you contact our customer support services to investigate & resolve your queries, monitor and improve our customer support responses. Certain online transactions may involve us calling you. They may also involve online chats. Please be aware that it is our general practice to monitor and in some cases record such interactions for staff training or quality assurance purposes or to retain evidence of a particular transaction or interaction.</li>
      <li>We intend to protect your personal information and to maintain its accuracy as confirmed by you. Credmate implements reasonable physical, administrative, and technical safeguards to help us protect your personal information from unauthorized access, use, and disclosure. For example, we encrypt all sensitive personal information such as email, credit card information when we transmit such information over the internet. We also require that our suppliers protect such information from unauthorized access, use, and disclosure.</li>
      <li>We blend security at multiple steps within our products with state of the art technology to ensure our systems maintain strong security measures. The overall data and privacy security design allows us defend our systems ranging from low hanging issue up to sophisticated attacks.</li>
      <li>We are committed to protecting your data as if it were our own. If you are a security enthusiast or a researcher and you have found a possible security vulnerability on our APP, we encourage you to report the issue to us responsibly. You could submit a bug report to us at info@credmate.in with detailed steps required to reproduce the vulnerability. We shall put best of our efforts to investigate and fix the legitimate issues in a reasonable time frame, meanwhile, requesting you not to publicly disclose it.</li>
    </ul> 
    <b>2.3 Sharing & Disclosure of data with Third Parties</b>
    <ul>
      <li>As required by law, at times we might be required to disclose your personal information including personal, transactional and financial information to relevant authorities. In some cases, when we believe that such disclosure is necessary to protect our rights, or the rights of others, or to comply with a judicial proceeding, court order, or legal process served on our website/app we would share such information pursuant to a lawful request from law enforcement agencies.</li>
      <li>Subject to explicit and prior consent from a User, we may use information created by your use of our App services, not including information collected from other sources such as e-mails etc. for marketing purposes. This consent is purely voluntary and you may at any time choose not to receive marketing materials from us by following the unsubscribe instructions included in each e-mail you may receive, by indicating so when we call you, or by contacting us directly. Further, if you want to remove your contact information from all our lists and newsletters, please click on the unsubscribe button on the emailers or send an email request to<a href="mailto:support@credmate.in"> support@credmate.in</a>.</li>
      <li>Subject to explicit and prior consent from a User, we may disclose certain information that is created by your use of our App services, not including information collected from other sources such as e-mails etc. to other affiliate entities and partners that are not acting as our suppliers or business partners. For the sake of clarity, Credmate does not sell or lease such information.</li>
      <li>Some of our campaigns/programs/related events may be co-branded, that is sponsored by both the third parties and us. If you sign up for such campaigns/programs/related events, please note that your information may also be collected by and shared with those third parties. We urge you to familiarize yourself with their privacy policies to gain an understanding of the manner in which they will handle information about you.</li>
      <li>Credmate may deliver third party online advertisements on the website/app but we may also advertise our activities and organizational goals on other websites/apps. We may collaborate with other website/app operators as well as network advertisers to do so. We request you to read and understand such concerned third party privacy policies to understand their practices relating to advertising, including what type of information they may collect about your internet usage. No personal information is shared with any third party online advertiser or website or app as part of any such activity. Credmate does not provide any information relating to your usage to such website operators or network advertisers.</li>
      <li>During your use of the App services, you may come across links to third party websites/apps that are not affiliated with Credmate. Credmate is not responsible for the privacy practices or the content of those other websites, or for any acts/ omissions by such third parties in the course of your transaction with them.</li>
    </ul> 
    <b>3. Account/Card Deletion</b>
    <p>
    We provide all our Users an option to request the deletion of a specific Card stored on his/her account through the support section on the Credmate App. Following such request, we delete all such information related to the specific card/account including but not limited to profile information, card data, transaction details, reward details, referrals details, statement details, google auth. sessions etc. that we are not required to retain. Complete account can never be delected once activated on Credmate. However you can deactivate your account by requesting support section but in case of deactivation of account the credit worthiness data collected and published by credmate against your mobile number/government ID entered will never be deleted. This provision has been kept to provide fair information to the credmate users and to avoid defaulters running away after doing a default to a lender and start using a new credmate account by joining again in some different form.<br>
    In certain circumstances, we may be unable to delete/deactivate your account, such as if there is any outstanding dispute or unresolved claims pending on your card/account. However, upon resolution of the issue preventing deletion, the information is immediately deleted and can’t be recovered thereafter. Please note that we may retain certain information if necessary for our own legitimate business interests such as fraud prevention and enhancing users’ safety and security OR to fulfil our legal obligations and compliance.</p>
    <b>4. Where do we store your data and for how long?</b>
    <p>The data we collect about you will be stored and processed in secure servers in order to provide the best possible user experience. For example, for a fast website or mobile application build up. We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
    <b>5. Cookies</b>
    <p>We use data collection devices such as “cookies”, etc. on certain parts of the App to help analyse the App Services, user interaction with the App, measure promotional effectiveness, and promote trust and safety. For the sake of clarity, “cookies” are small files placed on your device hard-drive/storage that assist us in providing the App Services. Please be informed that Credmate offers certain features via the App that are only available through the use of a “cookie”.<br>
    We also use cookies to allow you to enter a particular password less frequently during a session. Cookies also help us obtain information that assists in curating the Services more targeted to your interests. You are always free to decline our cookies if your device permits, although in that case you may not be able to use certain features on the app and you may be required to provide a password more frequently during a session.</p>
    <b>6. Disclaimer</b>
    <p>Credmate disclaims liability for all such information and shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages including but not limited to, damages for loss of profits, goodwill, use, data or other losses, costs, expenses, claims, proceedings, actions wherever and whenever occurring directly or indirectly resulting from:</p>
    <ul>
      <li>The publication of the information or the incorrectness, inaccuracy, unreliability of the information</li>
      <li>Any action taken, proceeding initiated, transaction entered into on the basis of such information displayed in the form of data ,graphs or pictorial form on Credmate timeline or user profile visible to any user.</li>
      <li>Unauthorised access to and / or alteration of the information including any virus attack, or other modification by virtue of hacking or otherwise or any loss caused by the download of the information</li>
      <li>Statements or conduct of any third party about the information.</li>
      <li>Any other matter relating to such information.</li>
    </ul> 
    <b>7. Changes to Legal agreement & Privacy Policy</b>
    <p>Credmate reserves the right to change this legal agreement and its policy from time to time. Any changes shall be effective immediately upon the posting of the revised legal agreement and Privacy Policy. We encourage you to periodically review this page for latest information on our legal agreement and privacy practices.</p>
    <b>8. Legal Agreement and Privacy Questions and Access</b>
    <p>If you have questions, concerns, or suggestions regarding our legal agreement and Privacy Policy, we can be reached using the contact information on our “Contact Us” page or at support@credmate.in. In certain cases, you may have the ability to view or edit your personal information online. In the event your information is not accessible online and you wish to obtain a copy of particular information you provided to Credmate, or if you become aware the information is incorrect and you would like us to correct it, please contact us immediately.<br>
    Before Credmate is able to provide you with any information or correct any inaccuracies, however, we may ask you to verify your identity and to provide other details to help us to respond to your request. We will contact you within 30 days of your request.</p>
      </div>


      <app-common-footer></app-common-footer>