import { Component } from "@angular/core";
import { PaymentgatewayService } from "src/app/core/payment/paymentgateway.service";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ICreateOrderRequest, IPayPalConfig } from "ngx-paypal";
import { ProductCartServiceService } from "src/app/core/cart/product-cart-service.service";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { PaymentModalComponent } from "./payment-modal/payment-modal.component";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent {
  cartItems: any[] = [];
  private subscription: Subscription;
  customerForm: FormGroup;

  public payPalConfig?: IPayPalConfig;

  constructor(
    private fb: FormBuilder,
    private paymentService: PaymentgatewayService,
    private cartService: ProductCartServiceService,
    public dialog: MatDialog,
  ) {
    this.customerForm = this.fb.group({
      fullName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      // Add other form controls as necessary
    });

    this.subscription = this.cartService.getCartItems().subscribe(items => {
      this.cartItems = items;
      
    });
  }


 ngOnInit() {
  this.initConfig();
 }


  
 private initConfig(): void {
  this.payPalConfig = {
      currency: 'CAD',
      clientId: 'AQn5ET-oB9yaUYUDcyr8iVAARkhGx4O1k29cUgiL_b2er4jBBK1eSkb9nTmEo5cJ8zKyTB1pjnmMwWlJ',
      createOrderOnClient: (data) => < ICreateOrderRequest > {
          intent: 'CAPTURE',
          purchase_units: [{
              amount: {
                  currency_code: this.cartItems[0].Currency,
                  value: this.cartItems[0].BilledAmt,
                  breakdown: {
                      item_total: {
                          currency_code: this.cartItems[0].Currency,
                          value: this.cartItems[0].BilledAmt,
                      }
                  }
              },
              items: [{
                  name: this.cartItems[0].name,
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                      currency_code: this.cartItems[0].Currency,
                      value: this.cartItems[0].BilledAmt,
                  },
              }]
          }]
      },
      advanced: {
          commit: 'true'
      },
      style: {
          label: 'paypal',
          layout: 'vertical'
      },
      onApprove: (data, actions) => {
          console.log('onApprove - transaction was approved, but not authorized', data, actions);
          actions.order.get().then((details: any) => {
              console.log('onApprove - you can get full order details inside onApprove: ', details);
          });

      },
      onClientAuthorization: (data) => {
          console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', 
          JSON.stringify(data));
          // this.openDialog(data, this.cartItems,  "Success");
      },
      onCancel: (data, actions) => {
          console.log('OnCancel', JSON.stringify(data), actions);
          //this.openDialog(data, this.cartItems, "Cancel");
      },
      onError: err => {
          console.log('OnError', err);
         // this.openDialog(err, this.cartItems, "Error");
          
      },
      onClick: (data, actions) => {
          console.log('onClick', JSON.stringify(data), actions);
         
      }
  };
}

openDialog(data: any, cartItems: any, status: string) {
  const dialogRef = this.dialog.open(PaymentModalComponent, {
    data: {
      receivedData: data,
      CartItem: this.cartItems,
      status: status
    }
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
  });
}

  proceedToPay() {
    if (this.customerForm.valid) {
      const customerInfo = this.customerForm.value;
      this.paymentService.proceedToPay(customerInfo).subscribe(
        (response) => {
          console.log("Payment Initiated", response);
          // Navigate to the payment gateway or perform other actions
        },
        (error) => {
          console.error("Payment failed", error);
        }
      );
    }
  }

  payWithPayPal() {
    this.paymentService.createPayment().subscribe(
      (response) => {
        console.log("Payment created", response);
        // Redirect user to PayPal for completing the payment
      },
      (error) => {
        console.error("Payment creation failed", error);
      }
    );
  }
}
