import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


interface LoginResponse {
  id: string;
  name: string;
  userId: string;
  username: string;
  type: string;
  status: string;
  creationTime: number;
  refresh_token: string;
  tokenExpiry: string;
}

interface Response {
  message: string;
  // any other properties that might be on the response
}

@Component({
  selector: 'app-authpopup',
  templateUrl: './authpopup.component.html',
  styleUrls: ['./authpopup.component.scss']
})
export class AuthpopupComponent {
  onSubmit() {
    throw new Error("Method not implemented.");
  }

  public username!: string;
  public password!: string;
  public email!: string;
  public type!: string;
  public hide: boolean = true;
  public name: any;
  public newPassword: any;


  isRegistering: boolean = false;
  isResettingPassword: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  login(): void {
    this.http
      .post<LoginResponse>(`${environment.login}`, {
        username: this.email,
        password: this.password,
      })
      .subscribe(
        (response: LoginResponse) => {
          localStorage.setItem("refresh_token", response.refresh_token);
          localStorage.setItem("ID", response.userId);
          document.cookie = `token=${response.refresh_token}; path=/`;
          this.router.navigate(["/home"]);
        },
        (error) => {
          console.error(error);
          const errorMessage = "Login failed. Please try again.";
          this.snackBar.open(errorMessage, "Close", { duration: 3000 });
        }
      );
  }
  togglePasswordVisibility(): void {
    this.hide = !this.hide;
  }

  
  register() {
    const requestBody = {
      name: this.name,
      username: this.username,
      type: this.type,
      password: this.password,
    };

    this.http
      .post<Response>(`${environment.register}`, requestBody)
      .subscribe({
        next: (response) => {
          console.log(response.message);
          this.router.navigate(['/auth/dashboard/overview']);
        },
        error: (error) => {
          console.log('API Error:', error);
          const errorMessage = error.message || 'An error occurred during Registration.';
          this.snackBar.open(errorMessage, 'Close', { duration: 3000 });
        }
      });
  }

  

  resetPassword() {
    // your password reset logic here
    this.http.post<Response>(`${environment.login}`, {
      email: this.email,
    })
    .subscribe(response => {
      console.log(response.message);
    });
  
  }
}
