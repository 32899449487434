import { Component } from '@angular/core';

@Component({
  selector: 'app-home-benefits',
  templateUrl: './home-benefits.component.html',
  styleUrls: ['./home-benefits.component.scss']
})
export class HomeBenefitsComponent {

}
