import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { trigger, state, style, animate,transition, } from '@angular/animations';

@Component({
  selector: 'app-howstepper-banner',
  templateUrl: './howstepper-banner.component.html',
  styleUrls: ['./howstepper-banner.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('visible', style({
        opacity: 1
      })),
      state('hidden', style({
        opacity: 0
      })),
      
    ])
  ]

})
export class HowstepperBannerComponent  implements AfterViewInit {
  @ViewChild('stepper') stepper!: MatStepper;
  activeImageIndex: number = 0;

  images = [
    { src: './assets/images/1.png', alt: 'User Registration' },
    { src: './assets/images/6.png', alt: 'Request Promise' },
    { src: './assets/images/5.png', alt: 'Lender Notification' },
    { src: './assets/images/4.png', alt: 'Fulfilling Promise' },
    { src: './assets/images/3.png', alt: 'Profile Completion' },
    { src: './assets/images/2.png', alt: 'Completion' }
  ];
  
  ngAfterViewInit(): void {
    this.stepper.selectedIndex = 0;
  }

  onStepSelected(event: StepperSelectionEvent) {
    this.activeImageIndex = event.selectedIndex;
  }
  
  showImage(index: number) {
    this.activeImageIndex = index;
  }
  
  hideImage() {
    this.activeImageIndex ;
  }


}
