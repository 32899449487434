import { Component } from '@angular/core';

@Component({
  selector: 'app-title-payment-modal',
  templateUrl: './title-payment-modal.component.html',
  styleUrls: ['./title-payment-modal.component.scss']
})
export class TitlePaymentModalComponent {

}
