export const environment = {
  production: false,
  login: '/api/nonauth/client/login',
  register: '/api/nonauth/client/register',
  logout: '/api/client/logout',
  userinfo: '/api/client/userinfo',
  projectSetup: '/api/auth/setup/project',
  moduleSetup:'/api/auth/setup/module',
  submoduleSetup:'/api/auth/setup/module/child',
  milestoneSetup: '/api/auth/setup/milestone',
  testTypeSetup: '/api/auth/setup/type',
  versionSetup:  '/api/auth/setup/version',
  logoutEndpoint: '/api/auth/client/logout',
  distictDropdown: '/api/auth/frontend/dinstinct/value',
  usecaseFactory: '/api/auth/quality/suite/usecase',
  testcaseFactory: '/api/auth/quality/suite/testcase',
  createUCMapping: '/api/auth/quality/suite/mapping/usecase',
  UCMapping: '/api/auth/quality/suite/mapping/usecase/get',
  milestoneMapping: '/api/auth/quality/suite/mapping/milestone',
  userList: '/api/auth/client/user/get',
  milestoneTree: '/api/auth/quality/suite/mapping/mil/uc/tc?searchValue=MLS-3',
  reset: '/api/reset',
  audio: '/api/post_audio',
  
  lmproduct: '/api/auth/lms',
  
};
